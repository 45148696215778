import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
const SkeletonPhotos = () => {
    return (

//         return  (<div className="lg:columns-3 sm:columns-2 gap-5">
//         {myPhotos.map((p) => (
//     <PhotoItem thumb={p.urls.regular} link={p.links.html} id={p.id} key={p.id}
//     description={p.alt_description} height={p.height} width={p.width} />
// ))}
// </div>)

// return (<div className="cursor-pointer py-2 inline-block w-full group">
//     {/* <figure className='relative h-64 w-full hidden md:block mb-3 sm:mb-0 mr-6 border border-gray-100 overflow-hidden rounded-lg transform group-hover:translate-x-0 group-hover:shadow group-hover:translate-y-0 transition duration-700 ease-out overflow-hidden'>
//       <div className="absolute w-full h-full object-cover rounded-lg transform group-hover:scale-105 transition duration-700 ease-out cursor-pointer"> */}
//       {/* <img src={props.thumb} className={`w-full aspect-[${props.width/props.height}]`}  alt="test" loading="lazy"/> */}
//       {/* </div>
//     </figure> */}

//         <a href={props.link} target="_blank" rel="noreferrer"><img src={props.thumb} className={`w-full aspect-[${props.width/props.height}]`}  alt={props.description} loading="lazy"/></a>
  
//   </div>

        <section>
            <div className="lg:columns-3 sm:columns-2 gap-5">
                {Array(9)
                    .fill()
                    .map((item, index) => (
                        <div className="cursor-pointer py-2 inline-block w-full group" key={index}>
                            <Skeleton height={360} />
                        </div>
                    ))}
            </div>
        </section>
    );
};

export default SkeletonPhotos;