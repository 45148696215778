import { DownloadIcon } from '@heroicons/react/outline';

export const saveSvg = (svgEl, name) => {
    svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    var svgData = svgEl.outerHTML;
    var preface = '<?xml version="1.0" standalone="no"?>\r\n';
    var svgBlob = new Blob([preface, svgData], {
        type: "image/svg+xml;charset=utf-8"
    });
    var svgUrl = URL.createObjectURL(svgBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

const Icons = () => {

	const downloadSVGHandler = (e)=>{
		e.stopPropagation(); 
		let s = e.target;
		console.log(e)
        saveSvg(s, 'duck')
	}

    return (<div id="natureIcons" className="mt-12 grid grid-cols-4 gap-6 md:grid-cols-8">
        <div className="group relative p-4 hover:bg-slate-200 hover:cursor-pointer border-slate-200 border-2 rounded" onClick={downloadSVGHandler}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path d="M158.8,88.8c-7.9,18.4-35.4,26.3-45.5,18.1c-4.1-4.1-2.9-13.5,1.4-21.3c4.3-7.9,6.4-11,7.1-17.6
			c2.1-19.9-13.8-37.7-34-37.6c-19.2,0-32.3,14.8-33.9,31c-7.6,3.3-15.3,4.9-21.9,4.7c-1.4,0-2.4,1.4-1.9,2.7
			c3.3,8.1,15.1,14.4,29.8,15.5c1.1,1.5,2.3,2.9,3.7,4.3c4.4,4.4,4.8,10.9-1.2,16.9c-6.1,6.1-9.5,14.7-9.5,23.9
			c0,13.8,8,25.7,19.9,31.5c9.9,5.5,21.9,8.7,34.8,8.7C154.8,170.4,188.5,124.4,158.8,88.8z M75.2,65.4c-3.8,0-6.8-3.1-6.8-6.8
			s3.1-6.8,6.8-6.8c3.8,0,6.8,3.1,6.8,6.8S79,65.4,75.2,65.4z"/>
            </svg>
			<DownloadIcon className="hidden h-5 w-5 absolute bottom-1 right-1 group-hover:block "/>
        </div>
        <div className="p-4 hover:bg-slate-200 hover:cursor-pointer border-slate-200 border-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path d="M170,124.2c-4.2-9.5-13.6-19.9-23.3-25.8c-10.7-9-26.9-14.3-42.9-13.2c-0.9-6.1-3.7-11.7-8.2-15.8
			c3.9-3.1,7.9-7.6,11.1-13.2c5.5-9.3,4.3-20.8,3.2-26.6c-0.4-2.1-2.5-3.3-4.5-2.6c-5.6,1.8-16.2,6.4-21.6,15.7
			c-0.2,0.4-0.5,0.8-0.7,1.2c-0.1-0.6-0.3-1.3-0.4-1.9c-2.5-10.5-11.3-18-16.1-21.4c-1.7-1.2-4.1-0.6-5,1.2
			c-2.7,5.2-7.2,15.9-4.7,26.3c1.5,6.4,4,12,7,16.1c-3.9,0.8-8.2,1.9-12,3.4C40.1,72.2,33.9,80,28.9,88.8
			c-7.3,12.9-7.3,25.1,10.5,31.1c2.9,1,13.5,4.8,13.5,4.8c-3.7,22.1,11.9,41.9,34.7,49.3c16.2,5.9,41,7.6,58.3,4
			C172.7,175.1,183.3,146.8,170,124.2z M90.5,46.7c2.1-3.5,5.9-5.4,8.3-6.2c1.1-0.4,2.4,0.3,2.6,1.5c0.4,2.5,0.6,6.8-1.4,10.3
			c-3.1,5.3-7.8,8.4-10.4,6.9C87,57.6,87.4,52.1,90.5,46.7z M62.5,82c-0.4,2.1-1.8,8.4-6.2,10.7C51.9,94.9,46,92.4,44,91.4
			c-0.4-0.2-0.6-0.6-0.5-1c0.4-2.1,1.8-8.4,6.2-10.7C54.1,77.5,60.1,80,62,81C62.4,81.1,62.6,81.6,62.5,82z"/>
		<ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -13.1233 9.6032)" cx="53" cy="86.2" rx="5.8" ry="5.8"/>
            </svg>
        </div>

        <div className="p-4 hover:bg-slate-200 hover:cursor-pointer border-slate-200 border-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                <circle cx="36" cy="145.1" r="8.9" />
                <path d="M153.5,77.4c-29.6-14-40.8-45-31.9-74C78.1-13.3,39.3,35.3,64.2,73.8c4.2,6.4,9.9,11.8,16.7,15.6c8,5.1,17.5,6.5,26.8,9.1
		c29.2,8.4,40.9,20.3,41.6,35.3c0.7,15.1-3,22.6-8.5,28.4c11.1-24.3-12.8-53.3-47.2-55.7c-8.9-0.8-17.4,0-25.1,2.2
		c-3.3-2.2-7.2-3.8-11.2-4.8l1.5-16.4C49.7,86.7,38.7,94,37,105.1c-8.7,3-16.6,9.6-20.1,15.3c-6.1,9-8.1,15.4-13,33.1
		c-6.5,2.3-1.7,12.3,4.1,8.6c13.8,9.4,25.8,8.9,38.3,7.4c2.7,2.9,5.8,5.6,9.3,7.9l-11.4,4.7c-4.3,1.7-2.3,8.4,2.2,7.5
		c0,0,25-4.7,25-4.7c4.9,1.5,10.4,2.6,15.8,3c-3.1,1.7-17.5,2.3-15.3,8.5c0.4,2.3,2.4,4.1,4.8,3.6c0,0,26.7-3.9,26.7-3.9
		c9.2-1.1,19.4-13,25.2-19.2c1.6-1.2,3-2.4,4.3-3.7c23.9,5.7,62.6,4.3,65.9-31C201.5,114.5,183.1,91.4,153.5,77.4z M52.3,138.9
		c-0.6,4.1-3,15.4-10.5,18.8c-7.4,3.4-17.6-2.1-21.1-4.2c-0.8-0.5-1.2-1.4-1-2.3c0.6-4.1,3-15.4,10.5-18.8
		c7.4-3.4,17.6,2.1,21.1,4.2C52,137.1,52.4,138,52.3,138.9z M52.3,107.5l-8.9-0.2c0-0.1,0-0.2,0-0.3c0.7-6.9,5.2-12.2,10.1-11.7
		L52.3,107.5z"/>
            </svg>
        </div>


        <div className="p-4 hover:bg-slate-200 hover:cursor-pointer border-slate-200 border-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path d="M42.6,36.7c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1S44.3,36.7,42.6,36.7z"/>
		<path d="M159,103.3c4.2-4.9,6.5-9.2,6.7-11.7c0,0-39.4-2.4-62.7-3.3c-7.3-0.3-15.1-1.7-22.7-2.7C73.1,80,63.3,71.3,63.3,71.3
			L65.8,45C66.6,32.4,56,24.7,44,24.7c-10.4,0-19,8.5-21.1,16.6H12.4c-6.6,0-12.4,6-12.4,7.8h23l0,0c1.6,4.8,6.1,8.8,12,10.8
			c0,0-13.5,16.2-13.5,37.9c0,33,26.2,47.8,57,49.1v26.4c0,1.1,0.9,2,2,2H81h1.7h0.6c1.1,0,2-0.9,2-2V147
			c21.6-0.6,37.6-9.8,45.5-22.7c2.2-1,4.3-2.1,6.3-3.3c37.7-1,62.8-4.8,62.8-9.3C200,108,185.2,104.9,159,103.3z M36.3,39.8
			c0-2.3,2.8-4.3,6.3-4.3c3.5,0,6.3,1.9,6.3,4.3c0,2.3-2.8,4.3-6.3,4.3C39.1,44.1,36.3,42.2,36.3,39.8z M72,130.5
			c-10.9-2.5-23.5-7.5-27.6-18.9c-2.3-7.3-0.5-16.3,4.9-21.8c2.8-2.7,6.6-4.2,10.3-4.6c-3.6,0.9-7.2,2.5-9.7,5.3
			c-4.7,5.4-6.2,13.9-3.9,20.6c2.5,7,9.1,11.5,15.7,14.4c10.3,4.5,21.7,6.3,32.8,7.1C87.1,132.8,79.5,132.1,72,130.5z"/>
            </svg>
        </div>

    </div>);

}

export default Icons;