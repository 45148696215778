import { useState, useEffect } from "react";
const NotFound = ()=> {

    const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/api")
      .then((res) => res.json())
      .then((data) => setData(data.message));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
       
        <p>{!data ? "Loading..." : data}</p>
      </header>
    </div>
  );


    //return <p>Nothing more!</p>
}

export default NotFound;