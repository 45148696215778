const PHOTO_DOMAIN = '/unsplash';

export async function getAllPhotos() {
  const response = await fetch(`${PHOTO_DOMAIN}`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || 'Could not fetch PHOTOS.');
  }
  const transformedQuotes = [];
  // console.log(response)
  // console.log("get data", data);

  for (const key in data) {
    const quoteObj = {
      id: key,
      ...data[key],
    };
    transformedQuotes.push(quoteObj);
  }
  return transformedQuotes;
}

const STOCK_API = '/stock';

export const getStock = async function(ticker) {
  let stockTicker = ticker || 'AAPL';
  const response = await fetch(`${STOCK_API}/${stockTicker}`);
  const data = await response.json();
  if (!response.ok) {  
    throw new Error(data.message || 'Could not fetch stock info.');
  }
  const transformedStocks = [];
  let tempData = data.results || [];

  tempData.forEach(function(s) {
    s.date = new Date(s.t);
    s.c = Math.floor(s.c*100)/100;
    s.h = Math.floor(s.h*100)/100;
    s.l = Math.floor(s.l*100)/100;
    transformedStocks.push(s);
  });
  // console.log("transformedStocks",tempData,transformedStocks);
  return transformedStocks;
}