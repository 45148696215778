import { useEffect } from "react";
import * as d3 from "d3";


const SkillChart = (props)=> {
    const { data, width, height } = props;

    useEffect(() => {
      drawChart();
    }, [drawChart]);
    //to do here
    function drawChart() {
        const margin = { top: 50, right: 50, bottom: 50, left: 50 };
        const yMaxValue = d3.max(data, d => d.score);

        d3.select('#skillChartWrapper')
        .select('svg')
        .remove();
        d3.select('#skillChartWrapper')
        .select('.tooltip')
        .remove();

        const svg = d3
            .select('#skillChartWrapper')
            .append('svg')
            // .attr('width', width + margin.left + margin.right)
            // .attr('height', height + margin.top + margin.bottom)
            .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`)
           
        const tooltip = d3
            .select('#skillChartWrapper')
            .append('div')
            .attr('class', 'tooltip')
            .style('visibility', 'hidden');
            
        const xScale = d3
        .scalePoint()
        .domain(data.map(d=>d.skill))
        .range([0,width]);
        const yScale = d3
        .scaleLinear()
        .domain([0,yMaxValue])
        .range([height, 0]);
  
        svg
            .append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(0,${height})`)
            .call(
            d3.axisBottom(xScale)
                .tickSize(-height)
                .tickFormat(''),
            );
        svg
            .append('g')
            .attr('class', 'grid')
            .call(
                d3.axisLeft(yScale)
                .tickSize(-width)
                .tickFormat(''),
            );
        svg
            .append('g')
            .attr('class', 'x-axis')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom().scale(xScale).tickSize(5));
        svg
            .append('g')
            .attr('class', 'y-axis')
            .call(d3.axisLeft(yScale));
        svg
            .append('g')
            .selectAll("circle")
            .data(data)
            .enter()
            .append("circle")
                .attr("cx", function (d) { return xScale(d.skill); } )
                .attr("cy", function (d) { return yScale(d.score); } )
                .attr("r", 8)
            .style("fill", "#60a5fa")
            .on('mouseover', (e, d) => {
                // console.log("mouse over", e, d);
                tooltip
                    .transition()
                    .duration(300)
                    .style('visibility', 'visible');
                tooltip
                    .html('<p class="header">'+d.skill+': '+d.score + '</p><p>'+d.description+'</p>')
                    .style("left", (e.pageX + 8) + "px")
                    .style("top", (e.pageY - 5) + "px");
            })
            .on('mouseout', (d) => {
                tooltip
                    .transition()
                    .duration(50)
                    .style('visibility', 'hidden');
            })
            // .on('mousemove', mousemove)

        svg
            .append("text")             
            .attr("transform",
                "translate(" + (width/2) + " ," + 
                                (height + margin.bottom) + ")")
            .style("text-anchor", "middle")
            .text("SKILL");

        svg
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x",0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text("LEVEL");   
    
            
        const focus = svg
            .append('g')
            .attr('class', 'focus')
            .style('display', 'none');
            focus.append('circle').attr('r', 5).attr('class', 'circle');

        
    }

    return (   
         <div id="skillChartWrapper" />
    )
}

export default SkillChart;