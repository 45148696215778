const chartData = [
    {skill:"HTML", score:9, description: 'fluent'},
{skill:"CSS", score:9, description: 'fluent'},
{skill:"Javascript", score:8, description: 'major libraries, api - google maps, Azure search, CK Editor...'},
{skill:"AngularJS", score:9, description: 'directives, service/factory...'},
{skill:"React", score:4, description: 'created test projects'},
{skill:"Vue", score:6, description: 'created prototypes'},
{skill:"ChartJS", score:8, description: 'pie, donut, bar charts, stacked bar charts etc.'},
{skill:"D3", score:6, description: 'line chart, stacked bar chart, sunburst chart, word cloud etc.'},
{skill:"SQL", score:7, description: 'all basic queries. Using temp table...'},
{skill:"Tableau", score:2, description: 'created a few dashboards at work'},
{skill:"Illustrator", score:10, description: 'SVG, icon fonts'},
{skill:"Photoshop", score:7, description: 'fluent'},
{skill:"Sketch", score:5, description: 'used in most projects, export css'}
]


export default chartData;