import { Fragment } from "react";
import Icons from "../components/Icons";
import Stocks from "../components/Stocks";

const CurrentProjects = ()=> {
    return (<Fragment>
        <h2>Nature Icons</h2>
        <p className="mb-4">I plan to share some SVG icons here. More to come. </p>
        <Icons />
        <h2>Data Visualizations (D3)</h2>
        <Stocks />
    </Fragment>);
   
}

export default CurrentProjects;