import { Route, Redirect, Switch } from 'react-router-dom';

import './App.css';
import About from './pages/About';
import Photos from './pages/Photos';
import NotFound from './pages/NotFound';
import MainHeader from './layout/MainHeader';
import Footer from './layout/Footer';
import { Fragment } from 'react';
import CurrentProjects from './pages/CurrentProjects';

function App() {
  return (
    <Fragment>
      
        <div className='mx-auto px-4 md:container' id="contentWrapper">
          <MainHeader />
          <main>
            <Switch>
              <Route path="/" exact>
                <Redirect to="/about" />
              </Route>
              <Route path="/about" exact>
                <About />
              </Route>
              <Route path="/photos" exact>
                <Photos />
              </Route>
              <Route path="/current" exact>
                <CurrentProjects />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </main>
        </div>
        <Footer />

    

    </Fragment>
  );
}

export default App;
