import { Fragment } from "react";
import SkillChart from "../components/SkillChart";
import Samples from "../components/Samples";
import chartData from "../data/chartData";

const About = ()=> {
    return (<Fragment>
        <h2 className="">Skills</h2>
        <p className="mb-4">Levels are subjective 🤔. <br />
        <span className="text-xs">1&ndash;4 learned<span className="mx-4 text-gray-300">|</span>5&ndash;7 limited practical experience<span className="mx-4 text-gray-300">|</span>&gt;7 fluent</span></p>
        
        <SkillChart data = {chartData} width={700} height={300}/>
        
        <Samples />
    </Fragment>);
   
}

export default About;