import { useEffect } from "react";
import * as d3 from "d3";

function formatDate(inputDate) {
    let dateIn = new Date(inputDate) || null
    if (!dateIn) return '';
    else {
        const theMonth = ('0' + (dateIn.getMonth() + 1)).slice(-2);
        const theDay = ('0' + dateIn.getDate()).slice(-2);
        return dateIn.getFullYear() + "/" + theMonth + "/" + theDay;
    }
}

const StockChart = (props) => {
    const { data, width, height } = props;

    useEffect(() => {
        drawChart();
    }, [drawChart]);
    //to do here
    function drawChart() {
        // console.log("data received", data);
        const margin = { top: 120, right: 120, bottom: 75, left: 50 };
        const yMaxValue = d3.max(data, d => d.h);
        const vMaxValue = d3.max(data, d => d.v);
        
        const priceChange = parseInt((data[data.length-1].c - data[0].c)/data[0].c*10000)/100;
        const lineColor = priceChange < 0 ? '#ef4444': '#4ade80';

        const priceChangeText = priceChange < 0? (priceChange+'%') : ('+'+priceChange+'%');

        // console.log(lineColor)
        const xScale = d3
            .scaleTime()
            .domain([data[0].date, data[data.length - 1].date])
            .range([0, width]);

        const yScale = d3
            .scaleLinear()
            .domain([0, yMaxValue])
            .range([height, 0]);
        
        var xVScale = d3.scaleBand()
            .domain(d3.range(0, data.length))
            .range([0, width])
  
        const yVScale = d3
            .scaleLinear()
            .domain([0, vMaxValue])
            .range([height, 0]);

        const lineGenerator = d3
            .line()
            .x((d, i) => Math.floor(xScale(d.date)))
            .y((d) => yScale(d.h))
        //.curve(d3.curveMonotoneX);

        //reset
        d3.select('#stockWrapper')
            .select('svg')
            .remove();

        d3.select('#stockWrapper')
            .select('.details')
            .remove();

        const svg = d3
            .select('#stockWrapper')
            .append('svg')
            // .attr('width', width + margin.left + margin.right)
            // .attr('height', height + margin.top + margin.bottom)
            .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        const details = d3
            .select('#stockWrapper')
            .append('div')
            .attr('class', 'details')
        //.style('visibility', 'hidden');

        svg
            .append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(0,${height})`)
            .call(
                d3.axisBottom(xScale)
                    .tickSize(-height)
                    .tickFormat(''),
            );
        svg
            .append('g')
            .attr('class', 'grid')
            .call(
                d3.axisLeft(yScale)
                    .tickSize(-width)
                    .tickFormat(''),
            );

//  volumn       
        svg
            .append('g')
            .selectAll("bar")
            .data(data)
            .enter().append("rect")
            .attr("class", "bar")
            .attr('x', function (data, i) {
              return xVScale(i);
            })
            .attr("y", function(d) { return yVScale(d.v); })
            .attr('width', xVScale.bandwidth())
            .attr("height", function(d) { return height - yVScale(d.v); })
            .attr("fill", "#1e293b");

        svg
            .append('g')
            .attr('class', 'y-axis')
            .call(d3.axisRight(yVScale))
            .attr('transform', `translate(${width},0)`);
     
        svg
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", width + margin.left + 30)
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text("VOLUMN");

// price
        svg
            .append('g')
            .attr('class', 'x-axis')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom().scale(xScale).tickSize(5));
        svg
            .append('g')
            .attr('class', 'y-axis')
            .call(d3.axisLeft(yScale));

        svg
            .append('g')
            .attr('class', 'stock-c')
            .append('path')
            .attr('d', d => {
                return lineGenerator(data)
            })
            .attr('fill', 'none')
            .attr('stroke', lineColor)
            .attr('stroke-width', 2)

        // svg
        //     .append('g')
        //     .attr('class', 'stock-low')
        //     .append('path')
        //     .attr('d', d => {
        //         return lLineGenerator(data)
        //     })
        //     .attr('fill', 'none')
        //     .attr('stroke', '#ef4444')
        //     .attr('stroke-width', 4)


        svg
            .append('rect')
            .attr('class', 'overlay')
            .attr('width', width)
            .attr('height', height)
            .on('mouseover', () => focus.style('visibility', 'hidden'))
            .on('mouseout', () => focus.style('visibility', 'hidden'))
            .on('mousemove', mousemove)

// chart labels
        svg
            .append("text")
            .attr("transform",
                "translate(" + (width / 2) + " ," +
                (height + margin.bottom-10) + ")")
            .style("text-anchor", "middle")
            //.attr("dominantBaseline", "alphabetic")
            .text(formatDate(data[0].t)+' - '+ formatDate(data[data.length-1].t) + ', '+priceChangeText);

        svg
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text("PRICE");

// chart focus, tooltips

        const focus = svg
            .append('g')
            .attr('class', 'focus')
            .style('visibility', 'hidden');

        focus.append('circle')
            .attr('r', 10)
            .attr('class', 'circle')

        function mousemove(e) {

            let tempPos = d3.pointer(e);
            // console.log("positions",tempPos);

            tempPos[0] = tempPos[0]<0? 0 : tempPos[0];
            tempPos[1] = tempPos[1]<0? 0 : tempPos[1];
            tempPos[0] = tempPos[0]>width? width : tempPos[0];
            tempPos[1] = tempPos[1]>height? height : tempPos[1];

            const tempDate = formatDate(xScale.invert(tempPos[0]))
            let posX = d3.bisect(data.map(o => o.date), new Date(tempDate))
            let currentData = data[posX];
            
            let stockDetails = `<ul><li><span class="label">Date:</span> <strong>${tempDate}</strong></li><li><span class="label">High:</span> <strong>$${currentData.h}</strong></li><li><span class="label">Low:</span> <strong>$${currentData.l}</strong></ul>`;
            let posY = currentData.h;
            // console.log("posX", posX, tempDate, xScale(new Date(tempDate)), yScale(posY))
            // const bisect = d3.bisector(d => d.label).left; //return index
            // const xPos = d3.pointer(this)[0];
            // const x0 = bisect(data, xScale.invert(xPos));
            // const d0 = data[x0];
            // console.log("position ", posX, posY)
            focus
                .transition()
                .duration(50)
                .style('visibility', 'visible')
                .attr(
                    'transform',
                    `translate(${xScale(new Date(tempDate))},${yScale(posY)})`,
                );
            details
                // .transition()
                // .duration(100)
                .style('opacity', 0.9);
            details
                .html(stockDetails)
            // .transition()
            // .duration(100)
            //     .style(
            //         'transform',
            //         `translate(${xScale(d0.label) + 30}px,${yScale(d0.value) - 30}px)`,
            // );
            // .style("left", (e.pageX + 8) + "px")
            //         .style("top", (e.pageY - 5) + "px");
        }
    }

    return (
        <div id="stockWrapper" className="chartWrapper bg-slate-900 rounded-xl p-6" />
    )
}

export default StockChart;