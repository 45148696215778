import { useEffect } from "react";
import useHttp from "../hooks/use-http";
import { getAllPhotos } from "../lib/api";
import PhotoList from "../components/PhotoList";
import SkeletonPhotos from "../components/SkeletonPhotos";

const Photos = ()=> {

    const {sendRequest, status, data: loadedPhotos, error} = useHttp(getAllPhotos, true);

    useEffect(()=>{
        sendRequest();
    }, [sendRequest]);

    if(status === 'pending') {
        return <SkeletonPhotos />
    }
    if(error) {
        return <p>{error}</p>
    }

    if(status === 'completed' && (!loadedPhotos || loadedPhotos.length === 0)) {
        return <div>No photos</div>
    }

    console.log(loadedPhotos);
    return <PhotoList photos={loadedPhotos} />
}

export default Photos;