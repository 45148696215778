import { NavLink } from 'react-router-dom';

const MainHeader = () => {
    return <header className="">
        <h1 className="my-0 pt-3">
            Wei Fukuyama
        </h1>
        <nav>
            <ul className="flex items-center h-16">
                <li className="mr-8 flex items-baseline space-x-4">
                    <NavLink exact className="no-underline font-medium text-gray-700 border-b-2 border-white  hover:border-yellow-500" activeClassName="border-yellow-500" to="/about">About Me</NavLink>
                </li>
                <li className="mr-8 flex items-baseline space-x-4">
                    <NavLink exact className=" no-underline font-medium text-gray-700 border-b-2 border-white  hover:border-yellow-500" activeClassName="border-yellow-500 no-underline"   to="/photos">Photos</NavLink>
                </li>
                <li className="mr-8 flex items-baseline space-x-4">
                    <NavLink exact className=" no-underline font-medium text-gray-700 border-b-2 border-white  hover:border-yellow-500" activeClassName="border-yellow-500 no-underline"   to="/current">Current Projects</NavLink>
                </li>
            </ul>
        </nav>
    </header>
}

export default MainHeader;