const PhotoItem = (props)=>{
    return (<div className="cursor-pointer py-2 inline-block w-full group">
    {/* <figure className='relative h-64 w-full hidden md:block mb-3 sm:mb-0 mr-6 border border-gray-100 overflow-hidden rounded-lg transform group-hover:translate-x-0 group-hover:shadow group-hover:translate-y-0 transition duration-700 ease-out overflow-hidden'>
      <div className="absolute w-full h-full object-cover rounded-lg transform group-hover:scale-105 transition duration-700 ease-out cursor-pointer"> */}
      {/* <img src={props.thumb} className={`w-full aspect-[${props.width/props.height}]`}  alt="test" loading="lazy"/> */}
      {/* </div>
    </figure> */}

        <a href={props.link} target="_blank" rel="noreferrer"><img src={props.thumb} className={`w-full aspect-[${props.width/props.height}]`}  alt={props.description} loading="lazy"/></a>
  
  </div>
    );

}

export default PhotoItem;