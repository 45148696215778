import { Fragment } from "react";
import PhotoItem from "./PhotoItem";

const PhotoList = (props) => {
    const myPhotos = props.photos;

    console.log("myPhotos", myPhotos);

    return  (
        <Fragment>
        <p className="mt-10 mb-4">Download my photos for free at <a href="https://unsplash.com/@shichijiro" target="_blank">Unsplash.com</a>.</p>

    <div className="lg:columns-3 sm:columns-2 gap-5">
                {myPhotos.map((p) => (
            <PhotoItem thumb={p.urls.regular} link={p.links.html} id={p.id} key={p.id}
            description={p.alt_description} height={p.height} width={p.width} />
        ))}
      </div>
      </Fragment>)
}

export default PhotoList;


