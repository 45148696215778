import { Fragment } from "react";


const Samples = () => {

    return (
        <Fragment>
            <h2 className="">Recent Projects</h2>
            <ul className="">
                <li><a href="http://ul.com" target="_blank">ul.com</a></li>
                <li><a href="http://mblm.com" target="_blank">mblm.com</a></li>
            </ul>
        </Fragment>)
}

export default Samples;


//coin buy sell, quantity, tax, net profit