import { Fragment, useEffect } from "react";
import useHttp from "../hooks/use-http";
import { getStock } from "../lib/api";
import StockChart from "./StockChart";

const Stocks = () => {

    const { sendRequest, status, data: loadedStocks, error } = useHttp(getStock, true);

    useEffect(() => {
        sendRequest();
    }, [sendRequest]);

    if (status === 'pending') {
        return <p>Loading</p>
    }
    if (error) {
        return <p>{error}</p>
    }

    if (status === 'completed' && (!loadedStocks || loadedStocks.length === 0)) {
        return <div>An error occurred or the app reached API limit. Please wait for 1 minute and try again.</div>
    }


    function handleChange(event) {
        console.log(event.target.value);
      }

      
    console.log(loadedStocks);
    return (<Fragment>
        <h3>Select stock&nbsp;&nbsp;
            <select name="ticker" className="dropDown">
                {
                    [
                        { label: 'Apple', value: 'AAPL' }
                    ].map(option => {
                        return (<option key={option.value} value={option.value}>{option.label}</option>)
                    })
                }
            </select>
        </h3>
        <StockChart data={loadedStocks} width={700} height={300}></StockChart>
    </Fragment>
    )
}

export default Stocks;